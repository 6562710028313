import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RandomService {

  constructor() { }

  public getRandomInt(max: number): number {
    return this.getRantomIntFromRange({min: 1, max});
  }

  public getRantomIntFromRange(range: Range): number {
    return Math.floor(Math.random() * (range.max - range.min + 1) + range.min);
  }

  public getRandomElementFromSet(set: Map<any, any>): any {
    const keys = Array.from(set.keys());
    const key = keys[this.getRandomInt(keys.length) - 1];
    return set.get(key);
  }
}

class Range { min = 1; max: number; }
