import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dtpm-gradient',
  template: `
    <svg class="gradient" [ngClass]="customClass">
      <defs>
      <linearGradient id="gradient" gradientTransform="rotate(90)">
        <stop class="gradient-color-stop-0" stop-color = "rgba(47,199,229)" offset = "0%"/>
        <stop class="gradient-color-stop-1" stop-color = "rgba(47,199,229)" offset = "50%"/>
        <stop class="gradient-color-stop-2" stop-color = "rgba(47,199,229)" offset = "100%"/>
      </linearGradient>
    </defs>
    <rect width="100%" height="100%" fill="url('#gradient')"/>
  </svg>
  `,
  styleUrls: ['./gradient.component.less']
})
export class GradientComponent {

  @Input() customClass: string;

  constructor() { }

}
