import { Directive, Output, EventEmitter, OnInit } from '@angular/core';

@Directive({
  selector: '[appDtpmScrollObserver]',
  host: {
    '(window:scroll)': 'windowScrolled($event)',
    '(scroll)': 'scrolled($event)',
  },
})
export class ScrollObserverDirective implements OnInit {
  scrolled: ($event: any) => void;
  windowScrolled: ($event: any) => void;
  windowScrollEvent: ($event: any) => void;
  elementScrollEvent: ($event: any) => void;

  constructor() { }

  @Output()
  scrolling = new EventEmitter<string>();

  ngOnInit(): void {
    ScrollObserverDirective.prototype.scrolled = function($event) {
      this.elementScrollEvent($event);
    };
    // handle window scroll
    ScrollObserverDirective.prototype.windowScrolled = function($event) {
        this.windowScrollEvent($event);
    };
    ScrollObserverDirective.prototype.windowScrollEvent = function($event) {
      const target = $event.target;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      const isReachingTop = scrollTop < this.topOffset;
      const isReachingBottom = (target.body.offsetHeight - (window.innerHeight + scrollTop)) < this.bottomOffset;
      const emitValue = { isReachingBottom, isReachingTop, originalEvent: $event, isWindowEvent: true };
      this.windowScrolled.emit(emitValue);
    };
    ScrollObserverDirective.prototype.elementScrollEvent = function($event) {
      const target = $event.target;
      const children = [...target.children];
      const totalHeight = children.reduce((total, child) => total + child.offsetHeight, 0);
      const viewWindow = {
        pixel: {
          from: target.scrollTop,
          to: target.scrollTop + target.offsetHeight
        },
        percent: {
          from: target.scrollTop / target.scrollHeight,
          to: (target.scrollTop + target.offsetHeight) / target.scrollHeight
        },
        childrenPercent: {
          from: null,
          to: null
        }
      };

      // Calculate Children Percent
      if (totalHeight < target.scrollTop) { // Fix behaviour when mobile devices let you scroll further than the size of the element
        viewWindow.childrenPercent.from = children.length;
      } else {
        children.forEach((child, i, childrenArr) => {
          // TODO Check that all the children are display: block or raise a warning
          const elementHeight = child.offsetHeight;
          const previousTotalHeight = childrenArr.slice(0, i).reduce((total, childEl) => total + childEl.offsetHeight, 0);
          const elementPercentFrom = this.getElementPercent(viewWindow.pixel.from, previousTotalHeight, elementHeight, i);
          const elementPercentTo = this.getElementPercent(viewWindow.pixel.to, previousTotalHeight, elementHeight, i);
          if (elementPercentFrom) {
            viewWindow.childrenPercent.from = elementPercentFrom;
          }
          if (elementPercentTo) {
            viewWindow.childrenPercent.to = elementPercentTo;
          }
        });
      }
      const emitValue = { visibleWindow: viewWindow, originalEvent: $event, isWindowEvent: false };
      this.scrolling.emit(emitValue);
    };
  }

  private getElementPercent(positionToCheck: number, previousTotalHeight: number, elementHeight: number, elementIndex: number) {
    const elementToTop = positionToCheck - previousTotalHeight;
    let elementPercent = null;
    if (elementToTop >= 0 && elementToTop < elementHeight) {
      elementPercent = elementIndex + 1 + elementToTop / elementHeight;
    }
    return elementPercent;
  }
}
