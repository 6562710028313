import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollEventModule } from 'ngx-scroll-event';
import { ScrollObserverDirective } from './scroll-observer.directive';
import { PolygonsComponent } from './polygons/polygons.component';

import { InlineSVGModule, InlineSVGDirective } from 'ng-inline-svg';
import { HttpClientModule } from '@angular/common/http';
import { GradientComponent } from './gradient/gradient.component';
import { RandomService } from './random.service';
import { ModuleWithProviders } from '@angular/compiler/src/core';

@NgModule({
  declarations: [ScrollObserverDirective, PolygonsComponent, GradientComponent],
  imports: [
    CommonModule,
    ScrollEventModule,
    HttpClientModule,
    InlineSVGModule.forRoot()
  ],
  exports: [
    CommonModule, ScrollEventModule, ScrollObserverDirective, PolygonsComponent,
    HttpClientModule, InlineSVGDirective, GradientComponent
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [ RandomService ]
    };
  }
}
