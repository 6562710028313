import { Component, OnInit, Input } from '@angular/core';
import { RandomService } from '../random.service';
import { posix } from 'path';

const SIZE = 60;
const GLOBAL_OFFSET: Position = {x: -SIZE / 2, y: -SIZE};

@Component({
  selector: 'app-polygons',
  templateUrl: './polygons.component.html',
  styleUrls: ['./polygons.component.less']
})

export class PolygonsComponent implements OnInit {

  private _figures: Polygon[] = [];
  private _grid: Map<Position, Polygon>;
  private readonly SIZE: number = SIZE;

  @Input() customClass = 'c0';

  constructor(private random: RandomService) { }

  ngOnInit() {
    const coordinates: Set<Position> = new Set();
    const coordToSet: Position[] = [{x: 1, y: 1}, {x: 10, y: 1}, {x: 14, y: 1}, {x: 25, y: 1}, {x: 4, y: 2}, {x: 7, y: 2}, {x: 9, y: 2},
      {x: 10, y: 2}, {x: 13, y: 2}, {x: 14, y: 2}, {x: 15, y: 2}, {x: 28, y: 2}, {x: 5, y: 3}, {x: 6, y: 3}, {x: 21, y: 3},
      {x: 24, y: 3}, {x: 1, y: 4}, {x: 1, y: 5}, {x: 10, y: 5}, {x: 11, y: 5}, {x: 19, y: 5}, {x: 26, y: 5}, {x: 1, y: 6},
      {x: 2, y: 6}, {x: 3, y: 6}, {x: 4, y: 6}, {x: 13, y: 6}, {x: 25, y: 6}, {x: 25, y: 7}, {x: 19, y: 8}, {x: 10, y: 9},
      {x: 24, y: 9}, {x: 28, y: 9}, {x: 3, y: 10}, {x: 10, y: 10}, {x: 24, y: 10}, {x: 28, y: 10}, {x: 2, y: 11}, {x: 3, y: 11},
      {x: 4, y: 11}, {x: 16, y: 11}, {x: 20, y: 11}, {x: 27, y: 11}, {x: 28, y: 11}, {x: 19, y: 12}, {x: 24, y: 12}, {x: 6, y: 13},
      {x: 13, y: 13}, {x: 25, y: 13}, {x: 1, y: 25}, {x: 25, y: 25}];
    coordToSet.forEach(coord => {coordinates.add(coord); });
    this._grid = this.generateHexagonGrid(undefined, coordinates);
    this.initCrawlers();
  }

  blinkIn(figure: Polygon) {
    if (figure.blinkingOut) {
      clearTimeout(figure.blinkingOut);
    }
    figure.addClass('blink');
  }
  blinkOut(figure: Polygon) {
    figure.blinkingOut = setTimeout(() => { figure.removeClass('blink'); }, 200);
  }
  select(figure: Polygon) {
    if (figure.coordinates) {
      if (figure.hasClass('hidden')) {
        figure.removeClass('hidden');
      } else {
        figure.addClass('hidden');
      }
    }
    this.initCrawler(figure.coordinates, 15);
  }

  get figures(): Polygon[] {
    return [...this._figures, ...Array.from(this._grid.values())];
  }

  private initCrawlers() {
    setInterval(() => this.initCrawler(), 5000);
  }
  private initCrawler(start?: Position, steps: number = 30): void {
    if (start === undefined) {
      const startFigure = this.random.getRandomElementFromSet(this._grid);
      start = startFigure.coordinates;
    }
    this.crawl(start, steps);
  }
  private crawl(actualPosition: Position, pendingSteps = 10, enteringDirection?: number) {
    if (!pendingSteps) {
      return;
    }
    this.glow(this.getPolygonAt(actualPosition));
    const newDirection: number = this.getNewDirection(enteringDirection);
    const nextPosition: Position = getAdjacentPosition(actualPosition, newDirection);
    setTimeout(() => this.crawl(nextPosition, --pendingSteps, newDirection), 50);
  }
  private glow(figure: Polygon): void {
    if (!figure) {
      return;
    }
    this.blinkIn(figure);
    setTimeout(() => this.blinkOut(figure), 100);
  }
  private getNewDirection(enteringDirection?: number): number {
    if (enteringDirection === undefined) {
      return this.random.getRandomInt(6) - 1;
    }
    const newDirection = this.random.getRantomIntFromRange({ min: enteringDirection - 1 + 6, max: enteringDirection + 1 + 6}) % 6;
    return newDirection;
  }

  private getPolygonAt(pos: Position): Polygon {
    return Array.from(this._grid.values()).filter(figure => samePosition(figure.coordinates, pos)).pop();
  }

  private generateHexagonGrid(totalDimensions: Position = {x: 30, y: 25}, coordinates?: Set<Position>): Map<Position, Polygon> {
    const grid: Map<Position, Polygon> = new Map();
    const offset: Position = {
      x: this.SIZE * 2,
      y: this.SIZE * Math.sqrt(3)
    };
    for (let row = 1; row <= totalDimensions.y; row++) {
      for (let col = 1; col <= totalDimensions.x; col++) {
        const currentOffset: Position = {
          x: (3 * col / 4 - 1 / 4) * offset.x,
          y: (row - (col % 2) / 2) * offset.y
        };
        const pol = this.generatePolygon(this.SIZE, currentOffset);
        if (coordinates) {
          if (hasPosition(coordinates, {x: col, y: row})) {
            pol.addClass('hidden');
          }
        }
        pol.coordinates = {x: col, y: row};
        grid.set(pol.coordinates, pol);
      }
    }
    return grid;
  }

  private generatePolygon(size: number = 10,
                          centerOffset: Position = {x: 0, y: 0}, rotateDeg: number = 0, sides: number = 6,
                          strokeColor: string = 'black', strokeWidth: number = 1, fillColor?: string ): Polygon {
    const pol: Polygon = new Polygon(size, centerOffset, sides, rotateDeg);
    pol.strokeColor = strokeColor;
    pol.strokeWidth = strokeWidth;
    pol.fillColor = fillColor;
    return pol;
  }
}
interface Position {
  x: number;
  y: number;
}
class Polygon {
  coordinates: Position;
  points: Position[] = [];
  strokeColor = 'black';
  strokeWidth = 1;
  private _centerOffset: Position;
  private _size: number;
  private _sides: number;
  private _rotateDeg: number;
  private _fillColor?: string;
  private _customClasses: Set<string> = new Set();
  blinkingOut: NodeJS.Timer;
  constructor(size: number, centerOffset: Position, sides: number, rotateDeg: number = 0) {
    this._size = size;
    this._centerOffset = centerOffset;
    this._sides = sides;
    this._rotateDeg = rotateDeg;
    this.addClass(`s${sides}`);
    this.points = this.getPoints(size, centerOffset, sides, rotateDeg);
  }

  addClass(cl: string) {
    this._customClasses.add(cl);
  }
  removeClass(cl: string) {
    this._customClasses.delete(cl);
  }
  hasClass(cl: string): boolean {
    return this._customClasses.has(cl);
  }
  getShrankPoints(shrink: number): Position[] {
    if (this._size <= shrink) {
      return [];
    }
    return this.getPoints(this._size - shrink, this._centerOffset, this._sides, this._rotateDeg);
  }
  getShrankPointsStr(shrink: number): string {
    return this.pointsToStr(this.getShrankPoints(shrink));
  }
  get coordinatesStr(): string {
    return `${this.coordinates.x} - ${this.coordinates.y}`;
  }
  get pointsStr(): string {
    return this.pointsToStr(this.points);
  }
  get fillColor(): string {
    return this._fillColor || 'none';
  }
  set fillColor(col: string) {
    this._fillColor = col;
  }
  get customClass(): string {
    return Array.from(this._customClasses).join(' ');
  }

  private getPoints(size: number, centerOffset: Position, sides: number, rotateDeg: number): Position[] {
    const points: Position[] = [];
    for (let i = 0; i < sides; i++) {
      const pointDeg = i * (360 / sides) + rotateDeg;
      const pointRad = pointDeg * Math.PI / 180;
      const offset: Position = {
        x: size * Math.cos(pointRad),
        y: size * Math.sin(pointRad)
      };
      points.push({
        x: centerOffset.x + offset.x + GLOBAL_OFFSET.x,
        y: centerOffset.y + offset.y + GLOBAL_OFFSET.y
      });
    }
    return points;
  }
  private pointsToStr(points: Position[]) {
    return points.reduce((tot, act): string => `${tot} ${act.x},${act.y}`, '');
  }
}

function hasPosition(coordinates: Set<Position>, c: Position) {
  return !Array.from(coordinates.values())
    .some((coord: Position) => samePosition(coord, c));
}

function samePosition(pos1: Position, pos2: Position): boolean {
  return pos1 && pos2 && pos1.x === pos2.x && pos1.y === pos2.y;
}

function getAdjacentPosition(pos: Position, direction: number) {
  const adjPos = {x: pos.x, y: pos.y};
  switch (direction) {
    case 1:
    case 2:
      adjPos.x++;
      break;
    case 4:
    case 5:
      adjPos.x--;
      break;
  }
  switch (direction) {
    case 0:
      adjPos.y--;
      break;
    case 3:
      adjPos.y++;
      break;
    case 1:
    case 4:
      if (!(adjPos.x % 2)) {
        adjPos.y--;
      }
      break;
    case 2:
    case 5:
      if (adjPos.x % 2) {
        adjPos.y++;
      }
      break;
  }
  return adjPos;
}
